import React, { useEffect, useState } from 'react';
import Helmet from 'react-helmet';

import { graphql } from 'gatsby';

KatalogTemplate.propTypes = {};

function KatalogTemplate({ data }) {
  const { katalog } = data;
  const [jQueryLoaded, setJQueryLoaded] = useState(false);
  const [flipBookInitialized, setFlipBookInitialized] = useState(false);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setTimeout(checkForJQuery, 100);
      setTimeout(checkForFlipBookInitialized, 100);
    }
  }, []);

  const checkForJQuery = () => {
    console.log('check for jquery', window.jQuery);
    if (
      typeof window !== 'undefined' &&
      typeof window.jQuery !== 'undefined' &&
      typeof window.jQuery.fn !== 'undefined'
      /* && typeof window.jQuery.fn.flipBook !== 'undefined' */
    ) {
      console.log('setting jQery loaded to true');
      setJQueryLoaded(() => true);
    } else {
      setTimeout(checkForJQuery, 100);
    }
  };

  const checkForFlipBookInitialized = () => {
    const jQueryLoaded =
      typeof window !== 'undefined' && typeof window.jQuery !== 'undefined' && typeof window.jQuery.fn !== 'undefined';
    if (jQueryLoaded && typeof window.jQuery.fn.flipBook !== 'undefined') {
      console.log('init flipbook');
      initFlipBook();
      setFlipBookInitialized(true);
    } else {
      console.log('init flipbook - another timeout');
      setTimeout(checkForFlipBookInitialized, 100);
    }
  };

  const initFlipBook = () => {
    const pdfURL = katalog.file.publicURL;
    const images = katalog.images;
    if (images && images.length > 0) {
      const articleImages = images.map((image, index) => ({
        src: image.publicURL,
        thumb: image.publicURL,
        title: `${katalog.name} Seite ${index}`,
      }));

      window.jQuery('#container').flipBook({
        pages: articleImages,
        rootFolder: '/',
        viewMode: 'webgl',
        assets: {
          flipMp3: '/js/flipbook/mp3/turnPage.mp3',
        },
        btnDownloadPages: {
          enabled: false,
        },
        btnDownloadPdf: {
          forceDownload: false,
          enabled: true,
          title: 'Download PDF',
          icon: 'fa-file',
          url: pdfURL,
          openInNewWindow: true,
        },
      });
    } else {
      window.jQuery('#container').flipBook({
        pdfUrl: pdfURL,
        rootFolder: '/',
        viewMode: 'webgl',
        assets: {
          flipMp3: '/js/flipbook/mp3/turnPage.mp3',
        },
      });
    }
  };

  return (
    <div className="wrap">
      <Helmet>
        <title>{katalog.name}</title>
        <script
          src="https://code.jquery.com/jquery-1.12.4.min.js"
          integrity="sha256-ZosEbRLbNQzLpnKIkEdrPv7lOy9C27hHQ+Xp8a4MxAQ="
          crossOrigin="anonymous"
        />
        <link rel="stylesheet" type="text/css" href="/js/flipbook/css/flipbook.style.css" />
        <link rel="stylesheet" type="text/css" href="/js/flipbook/css/font-awesome.css" />
        {jQueryLoaded && <script src="/js/flipbook/js/flipbook.min.js" />}
      </Helmet>
      <div>
        <div id="container" />
      </div>
    </div>
  );
}

export default KatalogTemplate;

export const KatalogQuery = graphql`
  query ($id: String, $language: String) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    katalog: cockpitKataloge(id: { eq: $id }) {
      id
      name
      type
      default
      images {
        publicURL
        childImageSharp {
          gatsbyImageData
        }
      }
      file {
        publicURL
      }
    }
  }
`;
